<template>
  <div class="ds-wrapper mb-4 md:mb-6" v-if="showPayment">
    <div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl">
      <div v-show="!showPaymentOptions">
        <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="showPaymentMethodsCard">{{ cardTitle }}</vs-button>
      </div>
      <div v-show="showPaymentOptions && !showPaymentMethods" class="md:px-2">
        <div class="mb-6 flex">
          <h4>{{ cardTitle }}</h4>
          <div class="grid grid-cols-3 gap-2 w-full ml-8 align-baseline">
            <vs-radio v-model="selectedOption" vs-value="balance">Total balance ({{ moneyFormat(prWithPartnerDetail.balances && prWithPartnerDetail.balances.balance ? prWithPartnerDetail.balances.balance : 0) }})</vs-radio>
            <vs-radio v-model="selectedOption" vs-value="overdue" v-if="showOverDueOption">Overdue ({{ moneyFormat(prWithPartnerDetail.balances.overdueBalance) }})</vs-radio>
            <div v-if="showOtherAmountOption">
              <vs-radio v-model="selectedOption" vs-value="other">
                Other amount <br>
              </vs-radio>
              <div class="pl-6">
                <div class="vs-input">
                  <money
                    v-model="selectedAmount"
                    v-bind="moneyMask"
                    v-show="selectedOption == 'other'"
                    name="selectedAmount"
                    class="mt-4"
                    data-vv-as="amount"
                    @blur.native="handleChangeSelectedAmount"
                  />
                </div>
                <span class="span-text-validation-danger" v-if="showSelectedAmountValidation"><span class="span-text-validation">The amount fee is required</span></span>
                <span class="span-text-validation-danger" v-else-if="showAmountRangeValidation"><span class="span-text-validation">Amount should not be greater than remaining balance</span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <p class="mb-2 sm:mb-3">Using</p>
          <div class="flex flex-col border-b-list">
            <template v-if="customerPaymentMethods.length > 0">
              <div class="flex flex-row justify-between align-center py-4" v-for="(paymentMethod, index) in customerPaymentMethods" :key="index">
                <div class="flex flex-row items-center" v-if="paymentMethod.type == 'CARD'">
                  <vs-radio v-model="selectedPaymentOption" vs-name="selectedPaymentOption" :id="index" :vs-value="paymentMethod" />
                  <label :for="index" class="flex flex-row items-center pointer">
                    <img :src="getCardType(paymentMethod.card_type)" :alt="paymentMethod.card_type" />
                    <p><span class="ml-4 md:ml-6">**** {{ paymentMethod.last_four_digits }}</span><span class="ml-4 md:ml-6">{{ getExpiredYear(paymentMethod) }}</span></p>
                  </label>
                </div>
                <div class="flex flex-row items-center" :class="['other', 'overdue'].includes(selectedOption) ? 'disabled' : ''" v-if="paymentMethod.type == 'DIRECT_DEBIT'">
                  <vs-radio v-model="selectedPaymentOption" vs-name="selectedPaymentOption" :id="index" :vs-value="paymentMethod" :disabled="['other', 'overdue'].includes(selectedOption)" />
                  <label :for="index" class="flex flex-row items-center pointer">
                    <img :src="directDebitCard" alt="bank" />
                    <p><span class="ml-4 md:ml-6">{{ paymentMethod.accountName }}</span><span class="ml-4 md:ml-6">{{ getAccountNumber(paymentMethod.accountNumber) }}</span></p>
                  </label>
                </div>
              </div>
            </template>

            <div class="flex flex-row justify-between align-center py-4" v-if="showEft">
              <div class="flex flex-row items-center">
                <vs-radio v-model="selectedPaymentOption" vs-name="selectedPaymentOption" :vs-value="'EFT'" />
                <label class="flex flex-row items-center pointer">
                  <img :src="payId" alt="EFT" width="50px" />
                  <p><span class="ml-4 md:ml-6">Bank transfer (EFT)</span></p>
                </label>
              </div>
            </div>
          </div>
          <hr class="mb-6" />
          <eft
            v-if="selectedPaymentOption === 'EFT'"
            :prWithPartnerDetail="prWithPartnerDetail"
            :selectedAmount="selectedAmount"
            @closePayment="showPaymentMethodsCard"
          />
        </div>
        <template v-if="selectedPaymentOption != 'EFT'">
          <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="submitPayment" :disabled="!proceedPayment">{{ buttonText }}</vs-button>
          <vs-button type="flat" @click="showPaymentMethodsCard" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Money } from "v-money";

import Eft from "../components/eft.vue";

// Credit Cards
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const payId = require("@/assets/images/cards/payid.svg");

export default {
  components: { Eft, Money },
  props: {
    title: { type: String },
    showPayment: { type: Boolean },
    isChangeMethodOpen: {type: Function},
    reloadPageContent: { type: Function},
    prWithPartnerDetail: {type: Object},
  },
  data() {
    return {
      selectedOption: "balance",
      paymentRequest: "",
      selectedAmount: 0,
      disablePaymentButton: false,
      showPaymentOptions: false,
      showPaymentMethods: false,
      selectedPaymentOption: {},
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      cardNumberInput: "make_payment_number_div",
      cardCvvInput: "make_payment_cvv_div",
      cardTitle: "Pay",
      payId: payId,
      showSelectedAmountValidation: false,
      showAmountRangeValidation: false,
      moneyMask: {
        prefix: "$ ",
        precision: 2,
      },
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["makePayment"]),
    ...mapGetters("customer", ["getCustomerPaymentMethods"]),

    handleChangeSelectedAmount() {
      if (this.paymentRequest.balances.balance < this.selectedAmount) {
        this.showAmountRangeValidation = true;
        return;
      } else if (!this.selectedAmount || this.selectedAmount == "") {
        this.showSelectedAmountValidation = true;
        return;
      }

      this.showAmountRangeValidation = false;
      this.showSelectedAmountValidation = false;
    },

    moneyFormat(data) {
      if (!data) {
        return "$ 0.00";
      }
      return `$ ${this.currencySeparator(data)}`;
    },

    currencySeparator(data) {
      return parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },

    changeMethod() {
      this.onClose();
      this.isChangeMethodOpen(true);
    },

    goToDetailPage() {
      this.$router.push({
        name: "paymentRequestDetail",
        params: {
          prId: this.$route.params.prId,
        },
      });
    },

    async submitPayment() {
      if (!this.selectedOption || !this.proceedPayment) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one option",
          color: "danger",
        });

        return;
      }

      if (this.selectedOption == "other") {
        if (this.showAmountRangeValidation) {
          return;
        } else if (this.selectedAmount == "") {
          this.showSelectedAmountValidation = true;
          return;
        }
      }

      this.$vs.loading();

      const data = {
        selectedOption: this.selectedOption,
        paymentRequestId: this.paymentRequest._id,
        amount: this.selectedAmount,
        ...this.selectedPaymentOption,
        paymentMode: "existing",
      };

      const payload = {
        data: data,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      await this.makePayment(payload).then((result) => {
        this.$vs.loading.close();

        if (result.data.message == "") {
          return;
        }

        const message = "Your payment of " + this.formatCurrency(this.selectedAmount || 0) + " was processed successfully.";
        this.showToastMessage("Payment processed", message, "success");
        this.reloadPageContent();
        this.showPaymentOptions = false;
        this.showPaymentMethods = false;
        this.selectedOption = "";
        this.selectedPaymentOption = {};
      }).catch((ex) => {
        this.disablePaymentButton = false;
        let message = "Sorry, your payment method could not be updated.";

        if (ex.response.data.message) {
          message = ex.response.data.message;
        }

        this.$vs.loading.close();
        this.showToastMessage("Payment failed", message, "error");
      });
    },

    navigateToNewTab(routeParams) {
      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },

    showPaymentMethodsCard() {
      this.showPaymentOptions = !this.showPaymentOptions;
      this.selectedOption = "balance";
      this.selectedAmount = this.paymentRequest.balances ? this.paymentRequest.balances.balance : 0;

      if (this.customerPaymentMethods.length) {
        this.selectedPaymentOption = this.customerPaymentMethods.find((item) => item.default == true);
      } else if (this.showEft) {
        this.selectedPaymentOption = "EFT";
      }
    },

    showAddPaymentMethod() {
      this.showPaymentMethods = !this.showPaymentMethods;

      if (this.selectedPaymentOption.type == "EFT") {
        this.showPaymentOptions = !this.showPaymentOptions;
      }
    },

    getCardType(cardType) {
      let card = "";

      switch (cardType) {
        case "visa":
          card = this.visaCard;
          break;

        case "mastercard":
          card = this.masterCard;
          break;

        case 'amexCard':
          card = this.amexCard;
          break;

        case "unionpay":
          card = this.unionunionpayCardpay;
          break;

        default:
          card = this.visaCard;
          break;
      }

      return card;
    },

    getExpiredYear(date) {
      if (!date) {
        return null;
      }

      return `${date.month}/${date.year.substring(2, 4)}`;
    },

    getAccountNumber(accNum) {
      return accNum.slice(0, accNum.length - 4).replace(/./g, "*")+ accNum.slice(accNum.length - 4);
    },

    async prepareSubmitPayment(paymentData) {
      this.selectedPaymentOption = paymentData;

       if (!this.proceedPayment) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one option",
          color: "danger",
        });
        return;
      }

      const data = {
        selectedOption: this.selectedOption,
        amount: this.selectedAmount,
        type: this.selectedPaymentOption.selectedPaymentMode,
        ...this.selectedPaymentOption,
        paymentMode: "new",
        paymentRequestId: this.paymentRequest._id,
      };

      const payload = {
        data: data,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();
      await this.makePayment(payload).then((result) => {
        this.$vs.loading.close();
        const message = "Your payment of " + this.formatCurrency(this.selectedAmount || 0) + " was processed successfully.";
        this.showToastMessage("Payment processed", message, "success");
        this.reloadPageContent();
      }).catch((ex) => {
        this.$vs.loading.close();
        let message = "Sorry, your payment method could not be updated.";

        if (ex.response.data.message) {
          message = ex.response.data.message;
        }

        this.showToastMessage("Payment failed", message, "error");
      });
    },
  },

  mounted() {
    this.paymentRequest = this.prWithPartnerDetail;
  },

  watch: {
    prWithPartnerDetail(newVal) {
      if (newVal) {
        this.paymentRequest = this.prWithPartnerDetail;
      }
    },

    async selectedOption(val) {
      this.showAmountRangeValidation = false;
      this.showSelectedAmountValidation = false;

      if (val == "balance") {
        this.selectedAmount = this.paymentRequest.balances ? this.paymentRequest.balances.balance : 0;
      } else if (val == "overdue") {
        this.selectedAmount = this.paymentRequest.balances ? this.paymentRequest.balances.overdueBalance : 0;
      } else if (val == "other") {
        this.selectedAmount = 0;

        if (this.selectedPaymentOption && this.selectedPaymentOption.type == "DIRECT_DEBIT") {
          const paymentMethod = this.customerPaymentMethods.find(item =>  item.type != "DIRECT_DEBIT");

          if (paymentMethod) {
            this.selectedPaymentOption = paymentMethod;
          }
        }
      }
    },
  },

  computed: {
    customerPaymentMethods() {
      let customerPaymentMethods = [ ...this.getCustomerPaymentMethods() ];
      customerPaymentMethods = customerPaymentMethods.filter(item => item.type != "EFT");

      return customerPaymentMethods;
    },

    buttonText() {
      return "Pay " + this.formatCurrency(this.selectedAmount || 0);
    },

    proceedPayment() {
      return Object.keys(this.selectedPaymentOption).length > 0 && this.selectedPaymentOption.type !== "EFT";
    },

    showEft() {
      const selectedPlan = this.prWithPartnerDetail.product;
      const paymentMethods = selectedPlan && selectedPlan.paymentMethods && selectedPlan.paymentMethods.length ? selectedPlan.paymentMethods : [];

      return paymentMethods.includes("EFT");
    },

    showOtherAmountOption() {
      const selectedPaymentMethod = Object.keys(this.selectedPaymentOption).length ? this.selectedPaymentOption.type : null;

      return (this.customerPaymentMethods.find(item => ["CARD", "EFT"].includes(item.type)) && (selectedPaymentMethod != "DIRECT_DEBIT")) ? true : false;
    },

    showOverDueOption() {
      const hasOverdueBalance = (this.prWithPartnerDetail.balances && this.prWithPartnerDetail.balances.overdueBalance > 0);

      return hasOverdueBalance && this.showOtherAmountOption;
    },
  }
};
</script>
<style>
  .align-baseline {
    align-items: baseline;
  }
</style>

